import { LetterStatus } from '../models/status';

export function getLetterStatuses(
  guess: string,
  solution: string
): LetterStatus[] {
  const guessArray = Array.from(guess.toUpperCase());
  const solutionArray = Array.from(solution.toUpperCase());
  const solutionTakenArray = solutionArray.map(() => false);

  const letterStatuses = Array(guess.length).fill(LetterStatus.Incorrect);

  guessArray.forEach((letter, i) => {
    if (!solutionArray.includes(letter)) {
      return (letterStatuses[i] = LetterStatus.Incorrect);
    }

    if (solutionArray[i] === letter) {
      solutionTakenArray[i] = true;
      return (letterStatuses[i] = LetterStatus.Correct);
    }
  });

  solutionArray.forEach((letter, i) => {
    if (solutionTakenArray[i] || !guessArray.includes(letter)) {
      solutionTakenArray[i] = true;
      return;
    }

    for (let j = 0; j < guessArray.length; j++) {
      if (
        guessArray[j] === letter &&
        letterStatuses[j] === LetterStatus.Incorrect
      ) {
        letterStatuses[j] = LetterStatus.InWord;
        solutionTakenArray[i] = true;
        break;
      }
    }
  });

  return letterStatuses;
}
