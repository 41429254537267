import './LoadingScreen.css';

export function LoadingScreen() {
  return (
    <div className="loading-screen">
      {/* <div className="loader-holder">
        <span className="loader loader-1 fa-beat-fade">A</span>
        &nbsp;
        <span className="loader loader-2 fa-beat-fade">B</span>
        &nbsp;
        <span className="loader loader-3 fa-beat-fade">C</span>
      </div> */}
    </div>
  );
}
