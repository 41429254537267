import { LetterStatus } from '../../models/status';

type Props = {
  value: string;
  status?: LetterStatus;
};

export const LetterBox = ({ status, value }: Props) => {
  const classes = ['letter-box'];
  if (status === LetterStatus.Correct) {
    classes.push('correct');
  } else if (status === LetterStatus.Incorrect) {
    classes.push('incorrect');
  } else if (status === LetterStatus.InWord) {
    classes.push('inword');
  }

  if (value === ' ') {
    return <div className={classes.join(' ')}>&nbsp;</div>;
  }

  return <div className={classes.join(' ')}>{value}</div>;
};
