import { KeyboardStatus, LetterStatus } from '../models/status';

export function getKeyboardStatus(
  guesses: string[],
  solution: string
): KeyboardStatus {
  const solutionArray = Array.from(solution.toUpperCase());
  const status = {} as KeyboardStatus;

  let guessArray: string[];
  let guess: string;
  let letter: string;
  for (let i = 0; i < guesses.length; i++) {
    guess = guesses[i].toUpperCase();
    guessArray = Array.from(guess);
    for (let j = 0; j < guessArray.length; j++) {
      letter = guessArray[j];

      if (!solution.toUpperCase().includes(letter)) {
        status[letter] = LetterStatus.Incorrect;
      } else if (solutionArray[j] === letter) {
        status[letter] = LetterStatus.Correct;
      } else if (status[letter] === undefined) {
        status[letter] = LetterStatus.InWord;
      }
    }
  }

  return status;
}
