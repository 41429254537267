import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { GameLength } from '../../config/config';
import { gameState } from '../../state/GameState';
import { GameStatus } from '../../models/status';

import './GameListView.css';

function GameListView() {
  const { lenStr } = useParams();

  const [gameStatuses, setGameStatuses] = useState<GameStatus[]>([]);

  useEffect(() => {
    gameState.getGameStatuses(Number(lenStr) as GameLength).then((statuses) => {
      setGameStatuses(
        Object.keys(statuses)
          .map(Number)
          .sort((a, b) => a - b)
          .map((k) => statuses[Number(k)])
      );
    });
  }, [lenStr]);

  if (!gameStatuses || gameStatuses.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <div className="game-list-view">
      <div className="game-list-buttons-holder">
        {gameStatuses.map((s) => {
          const classes = ['game-list-view-game-button'];
          let guessesBadge: ReactNode = null;

          if (s.guessesToSolve != null) {
            classes.push('solved');
            guessesBadge = (
              <div className="guesses-badge">{s.guessesToSolve}</div>
            );
          } else if (s.inProgress) {
            classes.push('in-progress');
          }

          return (
            <Link
              to={`/game/${lenStr}/${s.id}`}
              key={s.id}
              className="game-list-view-game-button-link"
            >
              <button className={classes.join(' ')}>{s.id}</button>
              {guessesBadge}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default GameListView;
