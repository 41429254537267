import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HeaderBar from './components/HeaderBar/HeaderBar';
import HomeView from './views/HomeView/HomeView';
import GameView from './views/GameView/GameView';
import GameListView from './views/GameListView/GameListView';
import SettingsView from './views/SettingsView/SettingsView';
import StatsView from './views/StatsView/StatsView';

import './App.css';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <div className="header-holder">
          <HeaderBar />
        </div>

        <div className="main-area">
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/game/:lenStr/:idStr" element={<GameView />} />
            <Route path="/game-list/:lenStr" element={<GameListView />} />
            <Route path="/stats" element={<StatsView />} />
            <Route path="/settings" element={<SettingsView />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
