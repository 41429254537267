import { ReactNode, useEffect, useRef } from 'react';
import { GameLength } from '../../config/config';
import { LetterStatus } from '../../models/status';

import './Guesses.css';
import { LetterBox } from './LetterBox';

type Props = {
  len: GameLength;
  guesses: string[];
  statuses: LetterStatus[][];
  currentGuess: string;
  currentGuessInvalid: boolean;
};

export const Guesses = ({
  len,
  guesses,
  statuses,
  currentGuess,
  currentGuessInvalid,
}: Props) => {
  const curGuessEl = useRef<HTMLDivElement>(null);

  let curGuessRow: ReactNode | null = null;

  useEffect(() => {
    if (curGuessEl.current) {
      curGuessEl.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [guesses]);

  if (
    guesses.length === 0 ||
    !statuses[statuses.length - 1].every((s) => s === LetterStatus.Correct)
  ) {
    let currentGuessPadded = currentGuess;
    while (currentGuessPadded.length < len) {
      currentGuessPadded += ' ';
    }

    curGuessRow = (
      <div
        className={`guess-row guess-row-${len} guess-row-cur ${
          currentGuessInvalid ? 'shake' : ''
        }`}
        key="cur"
        ref={curGuessEl}
      >
        {Array.from(currentGuessPadded).map((letter, pos) => {
          return <LetterBox value={letter} key={pos} />;
        })}
      </div>
    );
  }

  return (
    <div className="guesses">
      {guesses.map((guess, guessI) => {
        return (
          <div
            className={`guess-row guess-row-${len} guess-row-past`}
            key={guessI}
          >
            {Array.from(guess).map((letter, pos) => {
              return (
                <LetterBox
                  value={letter}
                  status={statuses[guessI][pos]}
                  key={pos}
                />
              );
            })}
          </div>
        );
      })}
      {curGuessRow}
    </div>
  );
};
