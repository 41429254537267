export const GAME_LENGTHS = [4, 5, 6, 7] as const;
export type GameLength = typeof GAME_LENGTHS[number];

export const ALL_LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const KEYBOARD_ROW_1_LETTERS = [
  'Q',
  'W',
  'E',
  'R',
  'T',
  'Y',
  'U',
  'I',
  'O',
  'P',
];

export const KEYBOARD_ROW_2_LETTERS = [
  'A',
  'S',
  'D',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
];

export const KEYBOARD_ROW_3_LETTERS = ['Z', 'X', 'C', 'V', 'B', 'N', 'M'];
