import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';

import './HomeView.css';
import { GAME_LENGTHS } from '../../config/config';

function HomeView() {
  return (
    <div className="home-view">
      <div className="home-buttons-holder">
        {GAME_LENGTHS.map((len) => {
          return (
            <div
              className={`home-buttons-group home-buttons-${len}dles`}
              key={len}
            >
              <button
                className={`home-buttons-choose home-buttons-${len}dles-choose`}
              >
                <Link to={`/game-list/${len}`}>{len}-dles</Link>
              </button>
              <button
                className={`home-buttons-random home-buttons-${len}dles-random`}
              >
                <Link to={`/game/${len}/random`}>
                  <FontAwesomeIcon icon={faRandom} />
                </Link>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomeView;
