import { GameLength, GAME_LENGTHS } from '../config/config';
import { Dle } from '../models/dles';
import { GameStatus } from '../models/status';

function saveGameLocalStorage(game: Dle) {
  window.localStorage.setItem(
    `${game.length}/${game.id}`,
    JSON.stringify(game)
  );
}

function getGameLocalStorage(len: GameLength, id: number) {
  const savedGame = window.localStorage.getItem(`${len}/${id}`);

  if (savedGame != null) {
    return JSON.parse(savedGame);
  }

  return null;
}

function getGameStatusesLocalStorage(len: GameLength) {
  const statusMap: {
    [id: number]: GameStatus;
  } = {};

  Object.keys(localStorage).forEach((k) => {
    const [gameLen, id] = k.split('/').map(Number);

    if (gameLen === len) {
      const game = JSON.parse(localStorage.getItem(k) as string);

      const solved =
        game.guesses[game.guesses.length - 1]?.toLowerCase() ===
        game.solution.toLowerCase();

      statusMap[id] = {
        id,
        guessesToSolve: solved ? game.guesses.length : null,
        inProgress: !solved && game.guesses.length > 0,
      };
    }
  });

  return statusMap;
}

type SolutionDict = {
  [gameLength in GameLength]: Promise<string[]>;
};

class GameState {
  private solutions: SolutionDict;

  constructor() {
    this.solutions = {} as SolutionDict;

    GAME_LENGTHS.forEach((l) => {
      this.getSolutions(l);
    });
  }

  async getSolutions(len: GameLength) {
    if (this.solutions[len] != null) {
      return this.solutions[len];
    }

    this.solutions[len] = (
      await import(`../data/solutions_${len}.json`)
    ).default;

    return this.solutions[len];
  }

  async getGameStatuses(len: GameLength) {
    const solutions = await this.getSolutions(len);
    const statuses = getGameStatusesLocalStorage(len);

    solutions.forEach((s, i) => {
      if (statuses[i + 1] === undefined) {
        statuses[i + 1] = {
          id: i + 1,
          guessesToSolve: null,
          inProgress: false,
        };
      }
    });

    return statuses;
  }

  saveGame(game: Dle) {
    saveGameLocalStorage(game);
  }

  async getGame(len: GameLength, id: number): Promise<Dle> {
    const savedGame = getGameLocalStorage(len, id);

    if (savedGame != null) {
      return new Dle(savedGame);
    }

    const solution = (await this.solutions[len])[id - 1];

    return new Dle({
      length: len,
      id,
      solution,
      guesses: [],
    });
  }
}

export const gameState = new GameState();
