import { GameLength } from '../config/config';
import { getLetterStatuses } from '../functions/getLetterStatus';
import { LetterStatus } from './status';

export interface DleBase {
  length: GameLength;
  id: number;
  solution: string;
  guesses: string[];
}

export class Dle implements DleBase {
  length: GameLength;
  id: number;
  solution: string;
  guesses: string[];

  constructor(dle: DleBase) {
    this.length = dle.length;
    this.id = dle.id;
    this.solution = dle.solution;
    this.guesses = dle.guesses;
  }

  public get guessStatuses(): LetterStatus[][] {
    return this.guesses.map((guess) => getLetterStatuses(guess, this.solution));
  }
}
