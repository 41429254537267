import { useEffect } from 'react';

import { Key } from './Key';
import { getKeyboardStatus } from '../../functions/getKeyboardStatus';

import './Keyboard.css';
import {
  KEYBOARD_ROW_1_LETTERS,
  KEYBOARD_ROW_2_LETTERS,
  KEYBOARD_ROW_3_LETTERS,
} from '../../config/config';

type Props = {
  onType: (value: string) => void;
  onBackspace: () => void;
  onEnter: () => void;
  guesses: string[];
  solution: string;
};

export const Keyboard = ({
  onType,
  onBackspace,
  onEnter,
  guesses,
  solution,
}: Props) => {
  const keyboardStatus = getKeyboardStatus(guesses, solution);

  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter();
    } else if (value === 'DELETE') {
      onBackspace();
    } else {
      onType(value);
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter();
      } else if (e.code === 'Backspace') {
        onBackspace();
      } else {
        const key = e.key.toUpperCase();
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onType(key);
        }
      }
    };
    window.addEventListener('keyup', listener);
    return () => {
      window.removeEventListener('keyup', listener);
    };
  }, [onEnter, onBackspace, onType]);

  return (
    <div className="keyboard">
      <div className="keyboard-row">
        {KEYBOARD_ROW_1_LETTERS.map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={keyboardStatus[key]}
          />
        ))}
      </div>
      <div className="keyboard-row">
        <div className="spacer key half-key" />
        {KEYBOARD_ROW_2_LETTERS.map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={keyboardStatus[key]}
          />
        ))}
        <div className="spacer key half-key" />
      </div>
      <div className="keyboard-row">
        <Key value="ENTER" flex={1.5} onClick={onClick} />
        {KEYBOARD_ROW_3_LETTERS.map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={keyboardStatus[key]}
          />
        ))}
        <Key value="DELETE" flex={1.5} onClick={onClick} />
      </div>
    </div>
  );
};
