import { ReactChild } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { LetterStatus } from '../../models/status';

type Props = {
  value: string;
  flex?: number;
  status?: LetterStatus;
  onClick: (value: string) => void;
};

export const Key = ({ status, flex, value, onClick }: Props) => {
  const classes = ['key'];
  if (status === LetterStatus.Correct) {
    classes.push('correct');
  } else if (status === LetterStatus.Incorrect) {
    classes.push('incorrect');
  } else if (status === LetterStatus.InWord) {
    classes.push('inword');
  }

  if (value.length > 1) {
    classes.push('long-key');
  }

  let keyContent: ReactChild = value;

  if (value === 'DELETE') {
    keyContent = <FontAwesomeIcon icon={faDeleteLeft} />;
  } else if (value === 'ENTER') {
    keyContent = <FontAwesomeIcon icon={faCircleCheck} />;
  }

  return (
    <button
      style={flex ? { flex } : { flex: 1 }}
      className={classes.join(' ')}
      onClick={(e) => {
        onClick(value);
        e.currentTarget.blur();
      }}
    >
      {keyContent}
    </button>
  );
};
