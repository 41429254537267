export enum LetterStatus {
  Incorrect,
  InWord,
  Correct,
}

export interface KeyboardStatus {
  [key: string]: LetterStatus;
}

export interface GameStatus {
  id: number;
  guessesToSolve: number | null;
  inProgress: boolean;
}
